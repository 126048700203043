@import url(https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap);
.body {
    font-family: 'Outfit', sans-serif;
    text-align: center;
    margin: 0;
    padding: 0;
}

.container {
    max-width: 600px;
    margin: 0 auto;
}

.header {
    padding: 20px;
}

.emailOwner {
    font-weight: 300;
    font-size: 40px;
}

.welcomeText {
    font-size: 25px;
    font-weight: 300;
}

.darkParagraph {
    font-weight: 300;
    font-size: 20px;
    max-width: 442px;
    margin: 0 auto;
}

.heroimg {
    max-width: 300px;
}


.content {
    padding: 20px;
}

.actionBtn1 {
    padding: 10px 50px;
    border-radius: 40px;
    border: none;
    background-color: #24B63B;
    color: white;
    font-size: 20px;
    margin: 24px 0;
}

.lightParagraph {
    color: #00172D80;
    max-width: 420px;
    margin: 0 auto 20px;

}

.TeamTextbold {
    font-weight: 500;
    font-size: 25px;
}

.MedicalCirclesLogo {
    max-width: 332px;
}

.supportText {
    font-weight: 400;
    font-size: 20px;
    color: #00172D;
}

.supportText a {
    color: #00172D;
}

.footer {
    padding: 10px;
    font-weight: 300;
    color: #00172D;
    font-size: 20px;
}

.footer a {

    color: #00172D;
}

.footerAddress {
    font-weight: 300;
    font-size: 20px;
    color: #00172D80;
}

.socialMediaGroup {
    display: flex;
    align-items: center;
    justify-content: center;
}

.socialMediaLinks {
    max-width: 35px;
    margin: 10px;
}

.body {
    font-family: 'Outfit', sans-serif;
    text-align: center;
    margin: 0;
    padding: 0;
}

.container {
    max-width: 600px;
    margin: 0 auto;
}

.header {
    padding: 20px;
}

.emailOwner {
    font-weight: 300;
    font-size: 40px;
}

.welcomeText {
    font-size: 25px;
    font-weight: 300;
}

.darkParagraph {
    font-weight: 300;
    font-size: 20px;
    max-width: 442px;
    margin: 0 auto;
}

.heroimg {
    max-width: 300px;
}


.content {
    padding: 20px;
}

.actionBtn {
    padding: 10px 50px;
    border-radius: 40px;
    border: none;
    background-color: #FD096F;
    color: white;
    font-size: 20px;
    margin: 24px 0;
}

.lightParagraph {
    color: #00172D80;
    max-width: 420px;
    margin: 0 auto 20px;

}

.TeamTextbold {
    font-weight: 500;
    font-size: 25px;
}

.MedicalCirclesLogo {
    max-width: 332px;
}

.supportText {
    font-weight: 400;
    font-size: 20px;
    color: #00172D;
}

.supportText a {
    color: #00172D;
}

.footer {
    padding: 10px;
    font-weight: 300;
    color: #00172D;
    font-size: 20px;
}

.footer a {

    color: #00172D;
}

.footerAddress {
    font-weight: 300;
    font-size: 20px;
    color: #00172D80;
}

.socialMediaGroup {
    display: flex;
    align-items: center;
    justify-content: center;
}

.socialMediaLinks {
    max-width: 35px;
    margin: 10px;
}

.rbc-header {
    font-size: 14px;  /* Example: change font size */
    font-weight: 700;
    text-align: center;
    color: #202224; /* Example: custom color */
    background-color: #F1F4F9; 
    height: 30px;
  }

  .diagonal-stripes {
    background-color: #e0f7fa; /* Base light blue color for the background */
    background-image: linear-gradient(135deg, rgba(0, 0, 255, 0.2) 25%, transparent 25%, transparent 50%, rgba(0, 0, 255, 0.2) 50%, rgba(0, 0, 255, 0.2) 75%, transparent 75%, transparent);
    background-size: 10px 10px; /* Adjust the size of the stripes */
  }

  .non-current-date {
    color: lightgray; /* Change this to your desired color */
    background-color: black; /* Optional: Change background color */
    pointer-events: none; /* Prevent interaction with these dates */
  }
.DetailedProfileParent {
  background-color: #f5f5f7;
  min-height: max-content;
}
.WhiteCardBox {
  background-color: white;
  border-radius: 0.7rem;
  padding: 1.5rem;
}

.ProfessionalDetails {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 1rem;
}

.DetailsTabs {
  border-left: 3px solid black;
  padding: 0.3rem 1rem;
}

.TabHeading {
  text-transform: capitalize;
}

.centaloverviewparent{
    min-height: 100vh;
    height: 100%;
    background-color: #fafafa;
    /* width: 53vw; */
    padding: 2rem;
    margin-left: -2rem;
    margin-top: -2rem;
}

.sectionHeading{
    margin: 1.5rem  0 .5rem;
    margin-left: .7rem;
}
.ProgressCardsSliderArea{
    max-width:100%;
}


.slick-slide > div{
    margin: 0 .5rem;
}
.DatePickerStyles_customDatePicker__lltx0 {
  border-radius: 16px;
}



.ProgressCardBgImg {
  width: 100%;
  height: 150px;
  border-radius: 10px;
  object-fit: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.custom-button {
  background-color: #546FFF;
  color: white;
  outline: none;
  border: none;
  padding: 0.6rem 1.2rem;
  border-radius: 0.5rem;
  text-transform: capitalize;
  width: 100%;
  font-size: .9rem;
  font-weight: 600;
}

.TaskTabHeadings{
    text-align: left;
}
.custom__WhiteCard{
    background-color: white;
    color: black;
    border-radius: .625rem;
    padding: 1.5rem;
    cursor: default;
}
/* ================== Flexbox Classes ============== */

.flex-aic {
  display: flex;
  align-items: center;
}
.flex-aicjcc {
  display: flex;
  align-items: center;
  justify-content: center;
}
.flex-aicjcsb {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.flex-aifsjcsb {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.flex-aicjccfdc {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.flexgap1 {
  grid-gap: 1rem;
  gap: 1rem;
}
.flexgap2 {
  grid-gap: 2rem;
  gap: 2rem;
}
.flexgap3 {
  grid-gap: 3rem;
  gap: 3rem;
}
.flexgap4 {
  grid-gap: 4rem;
  gap: 4rem;
}

/* =================== margin padding ============== */

.mar-tb1 {
  margin: 1rem 0;
}
.mar-tb15 {
  margin: 1.5rem 0;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Helvetica Neue , sans-serif";
}

a,
button {
  cursor: pointer;
  text-decoration: none;
}
button {
  border: none;
  outline: none;
  padding: 0.75rem 1.5rem;
  border-radius: 10px;
  font-weight: 600;
}

ul,
li {
  list-style: none;
}

html {
  scroll-behavior: smooth;
}

body {
  background-color: #f5f5f7 !important;
}


/* custom scroll bar style  */

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background-color: gainsboro;
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(139, 139, 139, 0.63); 
  border-radius: 10px;
  cursor: grabbing;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(31, 31, 31, 0.466); 
  cursor: grabbing;
}
.css-cveggr-MuiListItemIcon-root{
    min-width: 1.5rem !important;
    margin-right: .8rem !important;
}
