.rbc-header {
    font-size: 14px;  /* Example: change font size */
    font-weight: 700;
    text-align: center;
    color: #202224; /* Example: custom color */
    background-color: #F1F4F9; 
    height: 30px;
  }

  .diagonal-stripes {
    background-color: #e0f7fa; /* Base light blue color for the background */
    background-image: linear-gradient(135deg, rgba(0, 0, 255, 0.2) 25%, transparent 25%, transparent 50%, rgba(0, 0, 255, 0.2) 50%, rgba(0, 0, 255, 0.2) 75%, transparent 75%, transparent);
    background-size: 10px 10px; /* Adjust the size of the stripes */
  }

  .non-current-date {
    color: lightgray; /* Change this to your desired color */
    background-color: black; /* Optional: Change background color */
    pointer-events: none; /* Prevent interaction with these dates */
  }