@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Helvetica Neue , sans-serif";
}

a,
button {
  cursor: pointer;
  text-decoration: none;
}
button {
  border: none;
  outline: none;
  padding: 0.75rem 1.5rem;
  border-radius: 10px;
  font-weight: 600;
}

ul,
li {
  list-style: none;
}

html {
  scroll-behavior: smooth;
}

body {
  background-color: #f5f5f7 !important;
}


/* custom scroll bar style  */

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background-color: gainsboro;
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(139, 139, 139, 0.63); 
  border-radius: 10px;
  cursor: grabbing;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(31, 31, 31, 0.466); 
  cursor: grabbing;
}